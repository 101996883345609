import React from 'react'
import Link from 'gatsby-link'
import * as messages from '../messages.json'
import Layout from '../components/Layout'

class PageNotFound extends React.Component {
  render() {
    return (
      <Layout>
        <div className="row text-center no-gutters">
          <div className="col-md-8 offset-md-2">
            <h1 className="display-1">
              {messages.pages.pageNotFound.components.h1}
            </h1>
          </div>
        </div>
        <div className="row text-center no-gutters py-5">
          <div className="col-12">
            <Link className="display-5" to="/">
              {messages.pages.pageNotFound.components.link}
            </Link>
          </div>
        </div>
      </Layout>
    )
  }
}
export default PageNotFound
